import { render, staticRenderFns } from "./m-filter-list.vue?vue&type=template&id=2ed38dfc&scoped=true&"
import script from "./m-filter-list.vue?vue&type=script&lang=ts&"
export * from "./m-filter-list.vue?vue&type=script&lang=ts&"
import style0 from "./m-filter-list.vue?vue&type=style&index=0&id=2ed38dfc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ed38dfc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VCard,VChip,VCol,VForm,VRow,VTextField})
